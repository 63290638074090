import { computed } from '@vue/composition-api'
import store from '@/store'
import { User } from '@/domain/user'
import repository from '@/domain/userRepository'
import documentAnnotationRepository from '@/domain/documentAnnotationRepository'
import { useSystem } from '@/composables/useSystem'

const { isElectron } = useSystem()

export function useUser () {
  const findMe = async () => {
    await repository.findById('me').then(x => {
      store.commit('users/setMe', x)

      // Save user to userData for offline use
      if (isElectron.value) {
        const ipcRenderer = window.require('electron').ipcRenderer
        ipcRenderer.invoke('save-file', { filePath: '/user', file: JSON.stringify(x), fileName: 'me.json' })
      }
    })
  }
  const findMeOffline = async () => {
    if (isElectron.value) {
      const ipcRenderer = window.require('electron').ipcRenderer
      await ipcRenderer.invoke('get-saved-file', { filePath: '/user', fileName: 'me.json' }).then(x => {
        const me: User = JSON.parse(x.file)
        store.commit('users/setMe', me)
      })
    }
  }
  const clearUserData = async () => {
    documentAnnotationRepository.clearAll()

    if (isElectron.value) {
      const ipcRenderer = window.require('electron').ipcRenderer
      const userFilePaths = ['/agendaPackages', '/agendas', '/user']

      userFilePaths.forEach(async (filePath) => {
        await ipcRenderer.invoke('delete-saved-file', { dirPath: filePath })
      })
    }
  }

  // @ts-ignore: Unreachable code error
  const me = computed<User>(() => store.state.users.me)

  return {
    findMe,
    findMeOffline,
    clearUserData,
    me
  }
}
