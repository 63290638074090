




































import { defineComponent, computed, inject } from '@vue/composition-api'
import { BaseButton, BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { authMachineStateKey } from './Auth.vue'
import { useSystem } from '@/composables/useSystem'

export default defineComponent({
  name: 'sidebar-btn',
  components: {
    BaseButton,
    BaseIcon
  },
  props: {
    title: {
      type: String,
      required: true
    },
    date: Date,
    notDownloaded: {
      type: Boolean,
      default: false
    },
    category: String
  },
  setup (props) {
    const authMachineState = inject(authMachineStateKey)
    const { isElectron } = useSystem()

    const status = computed(() => {
      if (props.date) {
        const present = new Date()
        if (props.date >= present) {
          return 'Upcoming'
        } else {
          return 'Past Meeting'
        }
      }
      return null
    })

    return {
      authMachineState,
      isElectron,
      status
    }
  }
})
