<template>
  <v-app style="background-color: #F0F0F0;">
    <div v-if="state.matches('error')">
      <v-alert
        class="ui-app__error-alert"
        type="error"
        text
      >
        {{ state.context.error }}
        <BaseButton
          class="ui-app__error-close"
          @click.native="send({ type: 'CLEAR_ERROR' })"
          :tile="false"
          icon
          color="error"
        ><BaseIcon iconName="mdiClose" /></BaseButton>
      </v-alert>
    </div>

    <v-main>
      <transition name="slide-fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>

    <v-overlay class="ui-app__loading-overlay" color="white" opacity="1" :value="state.matches('loading')">
      <div class="ui-app__loading-image pa-5 d-flex justify-center align-end" :style="{ backgroundImage: `url(${SaskCoatOfArmsWelcome})` }">
        <v-progress-linear
          :value="percentage"
          class="ui-app__progress-bar mb-5"
          :indeterminate="!isElectron || authMachineState.matches('offline')"
          height="20"
          color="crPrimary"
        >
          <template v-if="isElectron && authMachineState.matches('online')" v-slot:default="{ value }">
            <strong>{{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>
      </div>
    </v-overlay>

    <SidebarBase
      :state="state"
      @toggleSidebar="send({ type: 'TOGGLE_SIDEBAR' })"
      @toggleSettings="send({ type: 'TOGGLE_SETTINGS' })"
      @clearAgendas="send({ type: 'CLEAR_AGENDAS' })"
      @clearAnnotations="send({ type: 'CLEAR_ANNOTATIONS' })"
      @selectAgendaCategory="send({ type: 'SELECT_AGENDA_CATEGORY', value: $event })"
      @loadAgendaManifest="send({ type: 'LOAD_AGENDA_MANIFEST', value: $event })"
      @loadAgendaDocument="send({ type: 'LOAD_AGENDA_DOCUMENT', value: $event })"
      @back="send({ type: 'BACK' })"
      @confirm="send({ type: 'YES' })"
      @cancel="send({ type: 'NO' })"
    />
  </v-app>
</template>

<script>
import { defineComponent, watch, provide, inject } from '@vue/composition-api'
import { sidebarMachine } from '@/machines/sidebarMachine'
import { electronSidebarMachine } from '@/machines/electronSidebarMachine'
import { useMachine } from 'xstate-vue2'
import SidebarBase from '@/components/SidebarBase.vue'
import { authMachineStateKey } from '@/components/Auth.vue'
import { BaseButton, BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import SaskCoatOfArmsWelcome from '@/assets/SaskCoatOfArmsWelcome.png'
import { usePoll } from '@/composables/usePoll'
import { useAgendas } from '@/composables/useAgendas'
import { useSystem } from '@/composables/useSystem'

export default defineComponent({
  name: 'App',
  components: {
    SidebarBase,
    BaseButton,
    BaseIcon
  },
  setup () {
    const { checkAgendaStatus, refresh, percentage } = useAgendas()
    const authMachineState = inject(authMachineStateKey)
    const { isElectron, checkElectron } = useSystem()

    if (typeof isElectron.value !== 'boolean') {
      checkElectron()
    }

    // Use different sidebar machine if electron is detected
    let state, send, service
    if (isElectron.value) {
      ({ state, send, service } = useMachine(electronSidebarMachine))
    } else {
      ({ state, send, service } = useMachine(sidebarMachine))
    }

    provide('sidebarMachineService', service)

    // Poll every 10 minutes - 600000
    const { clearPoll } = usePoll(() => checkAgendaStatus(), 600000)

    watch(refresh, () => {
      // Check if refresh has been set to false, indicating a refresh has completed
      if (!refresh.value) {
        clearPoll(true)
      }
    })

    return {
      SaskCoatOfArmsWelcome,
      isElectron,
      authMachineState,
      state,
      send,
      percentage
    }
  }
})
</script>

<style lang="scss">
html {
  @media (min-width: 960px) {
    font-size: 17px
  }
  @media (min-width: 1264px) {
    font-size: 18px
  }
  @media (min-width: 1904px) {
    font-size: 19px
  }
}
// Add semi bold font-weight
.v-application .font-weight-semi-bold {
  font-weight: 600 !important;
}
.v-system-bar {
  z-index: 5 !important;
}
.v-btn {
  text-indent: inherit;
}
.ui-app__error-alert {
  position: absolute;
  z-index: 10;
  top: 44px;
  left: 0;
  right: 0;
  width: 100%;
  .v-alert__wrapper .v-alert__content {
    padding-right: 42px;
  }
}
.ui-app__error-close {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}
.ui-app__loading-overlay {
  transition: ease-in-out 0.8s;
  .v-overlay__content {
    height: 100%;
    width: 100%;
  }
}
.ui-app__loading-image {
  height: 100%;
  background-size: 80%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.ui-app__progress-bar {
  max-width: 350px;
}
@media only screen and (min-width: 960px) {
  .ui-app__loading-image {
    background-size: 60%;
  }
}
@media only screen and (min-width: 1280px) {
  .ui-app__loading-image {
    background-size: 40%;
  }
}
</style>
