





























































































































import { defineComponent, computed, inject } from '@vue/composition-api'
import DialogConfirm from '@/components/DialogConfirm.vue'
import { BaseButton, BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import GovernmentSaskatchewan from '@/assets/governmentSaskatchewan.png'
import { useUser } from '@/composables/useUser'
import { authMachineSendKey, authMachineStateKey } from './Auth.vue'

export default defineComponent({
  name: 'settings',
  components: {
    DialogConfirm,
    BaseButton,
    BaseIcon
  },
  props: {
    state: Object
  },
  setup (_, { root }) {
    // TODO: migrate to `const store = useStore()`
    const store = root.$store

    const authMachineState = inject(authMachineStateKey)
    const authMachineSend = inject(authMachineSendKey)

    const { me } = useUser()
    const appVersion = computed(() => store.getters['system/getAppVersion'])
    const updateDate = computed(() => store.state.agendas.updateDate)

    return {
      GovernmentSaskatchewan,
      authMachineState,
      authMachineSend,
      appVersion,
      me,
      updateDate
    }
  }
})
