

































import { defineComponent, computed } from '@vue/composition-api'
import ButtonClose from '@/components/ButtonClose.vue'

export default defineComponent({
  name: 'sidebar-drawer',
  components: {
    ButtonClose
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  setup (_, { root }) {
    const widths = {
      xs: '100%',
      sm: '500px',
      md: 'calc((100% - 900px)/2 + 270px)',
      lg: 'calc((100% - 1185px)/2 + 350px)',
      xl: 'calc((100% - 1785px)/2 + 535px)'
    }

    const width = computed(() => widths[root.$vuetify.breakpoint.name])

    return { width }
  }
})
