import { computed } from '@vue/composition-api'
import store from '@/store'
import { Agenda } from '@/domain/agenda'
import { AgendaMedia } from '@/domain/agendaMedia'
import { AgendaManifest } from '@/domain/agendaManifest'
import { DocumentAnnotationFileName } from '@/domain/documentAnnotation'
import repository from '@/domain/agendaRepository'
import documentAnnotationRepository from '@/domain/documentAnnotationRepository'
import agendaMediaRepository from '@/domain/agendaMediaRepository'
import agendaManifestRepository from '@/domain/agendaManifestRepository'
import router from '@/router'
import { sub } from 'date-fns'
import { useSystem } from '@/composables/useSystem'

const sidebarAgendaCriteria = {
  groups: 'agenda:read:cabreader',
  pagination: false,
  meetingDateAfter: sub(new Date(), { years: 1 }),
  status2: ['preview', 'released', 'releasePreview']
}

export function useAgendas () {
  const findForSidebar = async () => {
    const { isElectron } = useSystem()
    store.commit('agendas/removeAgendasFromCache')

    await repository.findByCriteria(sidebarAgendaCriteria).then(x => {
      store.commit('agendas/addAgendasToCache', x)

      // Save agendas to userData for offline use
      if (isElectron.value) {
        const ipcRenderer = window.require('electron').ipcRenderer
        ipcRenderer.invoke('save-file', { filePath: '/agendas', file: JSON.stringify(x), fileName: 'agendas.json' })
      }
    })

    updateRefresh(false)

    // Redirect to agenda once state is updated
    if (router.currentRoute.name !== 'Home') {
      router.push({ name: 'Home' })
    }
  }
  const findForSideBarOffline = async () => {
    const ipcRenderer = window.require('electron').ipcRenderer
    await ipcRenderer.invoke('get-saved-file', { filePath: '/agendas', fileName: 'agendas.json' }).then(x => {
      let agendas: Agenda[] = JSON.parse(x.file)
      agendas = agendas.map(x => <Agenda>{
        ...x,
        meetingDate: x.meetingDate ? new Date(x.meetingDate) : undefined
      })
      store.commit('agendas/addAgendasToCache', agendas)
    })
  }
  const findManifest = async (id) => {
    const { isElectron } = useSystem()

    if (!isElectron.value) {
      await agendaManifestRepository.findById(id).then(x => {
        store.commit('agendas/addManifestToCache', x)
      })
    } else {
      const ipcRenderer = window.require('electron').ipcRenderer
      await ipcRenderer.invoke('get-saved-file', { filePath: `/agendaPackages/${id}`, fileName: 'manifest.json' }).then(x => {
        const manifest: AgendaManifest = JSON.parse(x.file)
        store.commit('agendas/addManifestToCache', manifest)
      })
    }
  }
  const findMediaBlob = async ({ id, file }) => {
    const { isElectron } = useSystem()

    if (!isElectron.value) {
      await agendaMediaRepository.findById({ id, file }).then(x => {
        store.commit('agendas/addMediaBlobToCache', x)
      })
    } else {
      const ipcRenderer = window.require('electron').ipcRenderer
      await ipcRenderer.invoke('get-saved-file', { filePath: `/agendaPackages/${id}`, fileName: file }).then(x => {
        const media: AgendaMedia = { blob: new Blob([x.file], { type: x.type }), type: x.type, agendaId: id, file }
        store.commit('agendas/addMediaBlobToCache', media)
      })
    }

    // Redirect to agenda once state is updated
    if (router.currentRoute.name !== 'Agenda') {
      router.push({ name: 'Agenda' })
    }
  }
  const findDocumentAnnotations = async (fileName) => {
    await documentAnnotationRepository.load(fileName).then(x => {
      store.commit('agendas/addDocumentAnnotationsToCache', x || undefined)
    })
  }
  const findSavedAgendaIds = async () => {
    const ipcRenderer = window.require('electron').ipcRenderer
    ipcRenderer.invoke('get-saved-agenda-ids', { dirPath: '/agendaPackages' }).then(savedAgendaIds => {
      store.commit('agendas/updateSavedAgendaIds', savedAgendaIds.agendaIds)
    })
  }
  const downloadAgendaZip = async (id) => {
    const ipcRenderer = window.require('electron').ipcRenderer
    const agendaZip = await agendaMediaRepository.findZipById(id)

    if (agendaZip && agendaZip.blob) {
      const buffer = Buffer.from(await agendaZip.blob.arrayBuffer())
      await ipcRenderer.invoke('save-file', { filePath: `/agendaPackages/${id}`, file: buffer, fileName: `${id}.zip` })
      await findSavedAgendaIds()
    }
  }
  const downloadAgendaZips = async () => {
    const ipcRenderer = window.require('electron').ipcRenderer
    let currentAgendaIds = store.getters['agendas/getCurrentAgendaIds']

    // Combine arrays, unique values only
    if (savedAgendaIds.value && savedAgendaIds.value.length) {
      currentAgendaIds = [...new Set([...savedAgendaIds.value, ...currentAgendaIds])]
    }

    if (currentAgendaIds && currentAgendaIds.length) {
      currentAgendaIds.forEach(async (id, index) => {
        const agendaZip = await agendaMediaRepository.findZipById(id)

        if (agendaZip && agendaZip.blob) {
          const buffer = Buffer.from(await agendaZip.blob.arrayBuffer())
          ipcRenderer.invoke('save-file', { filePath: `/agendaPackages/${id}`, file: buffer, fileName: `${id}.zip` }).then(() => {
            updatePercentage((index + 1) / currentAgendaIds.length * 100)
          })
        }
      })
    }
  }
  const setDocumentAnnotations = ({ annotations, fileName }: DocumentAnnotationFileName) => {
    documentAnnotationRepository.set({ annotations, fileName })
    store.commit('agendas/addDocumentAnnotationsToCache', annotations)
  }
  const checkAgendaStatus = async () => {
    if (refresh.value) {
      return true
    }

    const agendaIds = Object.keys(agendasForSidebar.value)
    const results = await repository.findByCriteria(sidebarAgendaCriteria)
    const resultIds = results.map(x => x.id)

    // Check for new IDs
    if (agendaIds.sort().join(',') !== resultIds.sort().join(',')) {
      return updateRefresh(true)
    }

    // Check generation dates
    const generationDateChange = results.some(x => {
      if (!x.generationDate && !agendasForSidebar.value[x.id].generationDate) {
        return false
      } else if (!x.generationDate || !agendasForSidebar.value[x.id].generationDate) {
        return true
      } else if (x.generationDate && agendasForSidebar.value[x.id].generationDate) {
        return (x.generationDate.getTime() !== agendasForSidebar.value[x.id].generationDate.getTime())
      }
      return false
    })

    if (generationDateChange) {
      return updateRefresh(true)
    }
  }
  const clearAgendaManifest = () => {
    store.commit('agendas/removeManifestFromCache')
  }
  const clearAgendas = () => {
    store.commit('agendas/removeAgendasFromCache')
    // Allow user to refresh agendas if they're cleared
    updateRefresh(true)
  }
  const clearDocumentAnnotations = () => {
    store.commit('agendas/removeDocumentAnnotationsFromCache')
  }
  const clearOldDocumentAnnotations = async () => {
    const agendaIds = Object.keys(agendasForSidebar.value)
    await documentAnnotationRepository.clearOld(agendaIds)
  }
  const clearAgendaZip = async (id) => {
    const ipcRenderer = window.require('electron').ipcRenderer
    await ipcRenderer.invoke('delete-saved-file', { dirPath: `/agendaPackages/${id}` })
    await findSavedAgendaIds()
  }
  const clearOldAgendaZips = async () => {
    const ipcRenderer = window.require('electron').ipcRenderer
    const currentAgendaIds = store.getters['agendas/getCurrentAgendaIds']

    if (savedAgendaIds.value && savedAgendaIds.value.length) {
      savedAgendaIds.value.forEach(async (id) => {
        if (!currentAgendaIds.includes(id)) {
          await ipcRenderer.invoke('delete-saved-file', { dirPath: `/agendaPackages/${id}` })
        }
      })
    }
  }
  const updateRefresh = (refresh) => {
    store.commit('agendas/updateAgendaRefreshStatus', refresh)
    return refresh
  }
  const updatePercentage = (percentage: number) => {
    store.commit('agendas/updatePercentage', percentage)
    return percentage
  }

  // @ts-ignore: Unreachable code error
  const agendasForSidebar = computed(() => store.state.agendas.agendas)
  // @ts-ignore: Unreachable code error
  const agendaManifest = computed(() => store.state.agendas.manifest)
  // @ts-ignore: Unreachable code error
  const agendaMediaBlob = computed<AgendaMedia>(() => store.state.agendas.mediaBlob)
  // @ts-ignore: Unreachable code error
  const documentAnnotations = computed(() => store.state.agendas.documentAnnotations)
  // @ts-ignore: Unreachable code error
  const refresh = computed<Boolean>(() => store.state.agendas.refresh)
  // @ts-ignore: Unreachable code error
  const percentage = computed<Boolean>(() => store.state.agendas.percentage)
  // @ts-ignore: Unreachable code error
  const savedAgendaIds = computed(() => store.state.agendas.savedAgendaIds)

  return {
    findForSidebar,
    findForSideBarOffline,
    findManifest,
    findMediaBlob,
    findDocumentAnnotations,
    findSavedAgendaIds,
    downloadAgendaZip,
    downloadAgendaZips,
    setDocumentAnnotations,
    checkAgendaStatus,
    clearAgendaManifest,
    clearAgendas,
    clearDocumentAnnotations,
    clearOldDocumentAnnotations,
    clearAgendaZip,
    clearOldAgendaZips,
    updateRefresh,
    updatePercentage,
    agendasForSidebar,
    agendaManifest,
    agendaMediaBlob,
    documentAnnotations,
    refresh,
    percentage,
    savedAgendaIds
  }
}
