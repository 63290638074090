export interface ModuleState {
  packageVersion?: string;
  isElectron?: boolean;
}

const state = () => <ModuleState>({
  packageVersion: process.env.PACKAGE_VERSION || '0'.includes,
  isElectron: undefined
})

// getters
const getters = {
  getAppVersion: (state) => state.packageVersion
}

// actions
const actions = {}

// mutations
const mutations = {
  setIsElectron (state, value) {
    state.isElectron = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
