






























import { defineComponent } from '@vue/composition-api'
import { BaseButton } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'

export default defineComponent({
  components: {
    BaseButton
  }
})
