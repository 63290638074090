import Vue from 'vue'
import { Agenda } from '@/domain/agenda'
import { AgendaMedia } from '@/domain/agendaMedia'

export interface ModuleState {
  agendas?: {[key: string]: Agenda};
  manifest?: Object;
  mediaBlob?: AgendaMedia;
  documentAnnotations?: Object;
  refresh: boolean;
  percentage: number;
  savedAgendaIds?: string[];
}

// state
const state = () => <ModuleState>({
  agendas: {},
  updateDate: undefined,
  manifest: undefined,
  mediaBlob: undefined,
  documentAnnotations: undefined,
  refresh: false,
  percentage: 0,
  savedAgendaIds: undefined
})

// getters
const getters = {
  getAgendaCategories: (state) => {
    const agendaCategories: any = []
    for (const id in state.agendas) {
      if (state.agendas[id].template && !agendaCategories.some(x => x === state.agendas[id].template.documentFolderName)) {
        agendaCategories.push(state.agendas[id].template.documentFolderName)
      }
    }
    // Sort alphabetically
    agendaCategories.sort()

    return agendaCategories
  },
  getSelectedCategoryAgendas: (state) => (agendaCategory) => {
    const categoryAgendas: any = { current: [], previews: [], past: [] }
    if (agendaCategory) {
      for (const id in state.agendas) {
        if (state.agendas[id].template.documentFolderName === agendaCategory) {
          const present = new Date()

          if ((state.agendas[id].status === 'preview' || state.agendas[id].status === 'releasePreview') && state.agendas[id].isPreview) {
            categoryAgendas.previews.push(state.agendas[id])
          } else if (state.agendas[id].meetingDate >= present) {
            categoryAgendas.current.push(state.agendas[id])
          } else {
            categoryAgendas.past.push(state.agendas[id])
          }

          // Sort first by active, then alphabetically, then by meeting date
          categoryAgendas.previews = sortAgendas(categoryAgendas.previews)
          categoryAgendas.current = sortAgendas(categoryAgendas.current)
          categoryAgendas.past = sortAgendas(categoryAgendas.past)
        }
      }
    }
    return categoryAgendas
  },
  getCurrentAgendaIds: (state) => {
    const currentAgendaIds: string[] = []
    for (const id in state.agendas) {
      const present = new Date()

      if (state.agendas[id].meetingDate >= present && (state.agendas[id].status === 'released' || state.agendas[id].status === 'releasePreview') && !state.agendas[id].isPreview) {
        currentAgendaIds.push(id)
      }
    }

    return currentAgendaIds
  }
}

// actions
const actions = {}

// mutations
const mutations = {
  addAgendasToCache (state, value) {
    value.forEach(agenda => {
      Vue.set(state.agendas, agenda.id, agenda)
    })
    state.updateDate = new Date()
  },
  removeAgendasFromCache (state) {
    state.agendas = {}
  },
  addManifestToCache (state, value) {
    state.manifest = value
  },
  removeManifestFromCache (state) {
    state.manifest = undefined
  },
  addMediaBlobToCache (state, value) {
    state.mediaBlob = value
  },
  removeMediaBlobFromCache (state) {
    state.mediaBlob = undefined
  },
  updateSavedAgendaIds (state, value) {
    state.savedAgendaIds = value
  },
  updateAgendaRefreshStatus (state, value) {
    state.refresh = value
  },
  updatePercentage (state, value) {
    state.percentage = value
  },
  addDocumentAnnotationsToCache (state, value) {
    state.documentAnnotations = value
  },
  removeDocumentAnnotationsFromCache (state) {
    state.documentAnnotations = undefined
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

function sortAgendas (agendas) {
  return agendas.sort((a1, a2) => {
    if (a1.isActive === a2.isActive) {
      if (a1.title.toLowerCase() === a2.title.toLowerCase()) {
        return a1.meetingDate < a2.meetingDate ? -1 : 1
      }
      return a1.title.toLowerCase() < a2.title.toLowerCase() ? -1 : 1
    }
    return a2.isActive - a1.isActive
  })
}
