import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import App from '@/router/views/App.vue'
import Home from '@/router/views/Home.vue'
import Agenda from '@/router/views/Agenda.vue'
import Callback from '@/router/views/Callback.vue'
import LoggedOut from '@/router/views/LoggedOut.vue'
import { titlePath } from '@/utils/title'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: App,
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home
      }, {
        path: '/agenda',
        name: 'Agenda',
        component: Agenda
      }
    ]
  }, {
    path: '/callback',
    name: 'Callback',
    component: Callback
  }, {
    path: '/logged-out',
    name: 'LoggedOut',
    component: LoggedOut
  }
]

const router = new VueRouter({
  mode: process.env.IS_ELECTRON ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // Update browser title
  document.title = `Cabreader ${titlePath(to.fullPath)}`

  next()
})

export default router
