import Vue from 'vue'
import VueI18n, { LocaleMessages } from 'vue-i18n'

Vue.use(VueI18n)

const messages: LocaleMessages = {
  en: {
    global: {
      button: {
        submit: 'Submit'
      }
    },
    addin: {
      confirmModal: {
        proceedText: 'Are you sure you wish to proceed?'
      },
      approve: {
        confirm: {
          buttonText: 'Approve'
        },
        confirmModal: {
          title: 'Marking Document as Approved',
          text: 'You are about to mark this document as approved.'
        }
      },
      finish: {
        confirm: {
          buttonText: 'Finish'
        },
        confirmModal: {
          title: 'Marking Document as Finished',
          text: 'You are about to mark this document as finished.'
        }
      },
      draft: {
        confirm: {
          buttonText: 'Move to Collaboration'
        },
        confirmModal: {
          title: 'Moving to Collaboration',
          text: 'You are about to move this document to moving to collaboration.'
        }
      },
      collaborationMoving: {
        confirm: {
          buttonText: 'Collaboration'
        },
        confirmModal: {
          title: 'Collaboration',
          text: 'You are about to move this document to collaboration.'
        }
      },
      collaborationInProgress: {
        confirm: {
          buttonText: 'Moving to Approval'
        },
        confirmModal: {
          title: 'Moving to Approval',
          text: 'You are about to move this document to moving to approval.'
        }
      },
      approvalMoving: {
        confirm: {
          buttonText: 'Approval'
        },
        confirmModal: {
          title: 'Approval',
          text: 'You are about to move this document to approval.'
        }
      }
    },
    workflow: {
      document: {
        create: {
          cancel: {
            buttonText: 'Cancel'
          },
          idle: {
            buttonText: 'Create Draft Document'
          },
          creating: {
            heading: 'Creating your document'
          },
          success: {
            heading: 'Your document has been created.',
            buttonText: 'Edit Document'
          },
          failure: {
            heading: 'Something went wrong...',
            buttonText: 'Retry'
          }
        }
      }
    },
    document: {
      status: {
        draft: 'Draft',
        'collaboration-moving': 'Moving to Collaboration',
        'collaboration-required': 'Requires your Collaboration',
        'collaboration-in-progress': 'In Collaboration',
        'approval-moving': 'Moving to Approval',
        'approval-required': 'Requires your Approval',
        'approval-in-progress': 'In Approval',
        approved: 'Approved',
        archived: 'Archived'
      },
      comment: {
        commentCount: 'Comment | Comments'
      },
      attachment: {
        attachmentCount: '{count} Document Attachment | {count} Document Attachments'
      }
    }
  }
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages
})
