export const titlePath = (fullPath: string) => {
  if (fullPath !== '/') {
    return fullPath
      .replace('/app/', '')
      .split('/')
      .map(x => {
        const parts = x.split('-')
        return parts.map(y => y.charAt(0).toUpperCase() + y.slice(1)).join(' ')
      })
      .join(' - ')
  }
  return ''
}
