import { User } from '@/domain/user'

export interface ModuleState {
  me?: User;
}

const state = () => <ModuleState>({
  me: undefined
})

// getters
const getters = {
}

// actions
const actions = {}

// mutations
const mutations = {
  setMe (state, value) {
    state.me = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
