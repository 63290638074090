import { AgendaApi } from '@zucommunications/gsk-docshare-web-api'
import axios from '@/utils/axios'
import { AgendaMedia } from '@/domain/agendaMedia'
import RepositoryError from '@/domain/repositoryError'

const BASE_PATH = window.Cypress ? '' : process.env.VUE_APP_SYMFONY_API_URL
const api = new AgendaApi(undefined, BASE_PATH, axios)

class AgendaMediaRepository {
  async findById ({ id, file }) {
    try {
      const response = await api.getAgendaDownloadItemPdfAgendaItem(`agenda=${id};document-name=${file}`, {
        responseType: 'blob'
      })

      return <AgendaMedia> { blob: response.data as unknown, type: response.headers['content-type'], agendaId: id, file }
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async findZipById (id) {
    try {
      const response = await api.getAgendaDownloadZipAgendaItem(id, {
        responseType: 'blob'
      })
      return <AgendaMedia> { blob: response.data as unknown, type: response.headers['content-type'], agendaId: id }
    } catch (err) {
      throw new RepositoryError(err)
    }
  }
}

export default new AgendaMediaRepository()
