





import { defineComponent, inject, computed, ref, watch, onMounted, onBeforeUnmount } from '@vue/composition-api'
import PSPDFKit from 'pspdfkit'
import { useAgendas } from '@/composables/useAgendas'
import { useSystem } from '@/composables/useSystem'
import { Interpreter } from 'xstate'
import { useService } from 'xstate-vue2'
import { DocumentAnnotation } from '@/domain/documentAnnotation'

export default defineComponent({
  name: 'pdf-container',
  props: {
    pdfFile: String,
    fileName: String
  },
  setup (props, { root }) {
    const service = inject('sidebarMachineService') as Interpreter<any, any, any, any>
    const { send } = useService(service)
    const { isElectron } = useSystem()
    const isDevelopment = process.env.NODE_ENV !== 'production'
    const pdfContainer = ref<HTMLElement | null>(null)

    const {
      findDocumentAnnotations,
      setDocumentAnnotations,
      clearDocumentAnnotations,
      documentAnnotations,
      refresh
    } = useAgendas()

    const toolbarItems: any = computed(() => [
      {
        type: 'custom',
        id: 'home',
        title: 'Home',
        className: 'home-nav-item',
        icon: '/img/cabreaderIcon.png',
        onPress: () => {
          root.$router.push({ name: 'Home' })
        }
      },
      { type: 'spacer' },
      { type: 'search' },
      { type: 'annotate', mediaQueries: ['(max-width: 10000px)'] },
      { type: 'ink' },
      { type: 'highlighter' },
      { type: 'text-highlighter' },
      { type: 'ink-eraser' },
      { type: 'image' },
      { type: 'stamp' },
      { type: 'note' },
      { type: 'text' },
      { type: 'line' },
      { type: 'arrow' },
      { type: 'rectangle' },
      { type: 'ellipse' },
      { type: 'polygon' },
      { type: 'polyline' },
      { type: 'undo' },
      { type: 'redo' },
      { type: 'sidebar-thumbnails' },
      { type: 'sidebar-document-outline' },
      { type: 'sidebar-annotations' },
      { type: 'sidebar-bookmarks' },
      {
        type: 'custom',
        id: 'refresh',
        title: 'Refresh',
        icon: '/img/refresh.png',
        disabled: !refresh.value,
        onPress: () => {
          send('REFRESH_AGENDAS')
        }
      },
      { type: 'debug' }
    ])

    const loadPSPDFKit = async () => {
      if (pdfContainer.value) {
        PSPDFKit.unload(pdfContainer.value)

        if (props.pdfFile && props.fileName) {
          await findDocumentAnnotations(props.fileName)

          const configuration: any = {
            document: props.pdfFile,
            container: pdfContainer.value,
            enableHistory: true,
            licenseKey: isElectron.value ? process.env.VUE_APP_PSPDFKIT_ELECTRON_LICENSE_KEY : process.env.VUE_APP_PSPDFKIT_WEB_LICENSE_KEY,
            autoSaveMode: PSPDFKit.AutoSaveMode.IMMEDIATE,
            toolbarItems: toolbarItems.value,
            styleSheets: ['/css/pspdfkit.css'],
            baseUrl: isElectron.value ? 'app://./js/' : undefined
          }

          if (isDevelopment) {
            configuration.baseUrl = `${process.env.VUE_APP_WEBPACK_SERVER_URL}js/`
            configuration.licenseKey = undefined
          }

          if (documentAnnotations.value) {
            configuration.instantJSON = documentAnnotations.value
          }

          await PSPDFKit.load(configuration).then(async (instance) => {
            instance.setViewState(viewState => (
              viewState.set('sidebarPlacement', PSPDFKit.SidebarPlacement.END)
            ))

            instance.addEventListener('annotations.didSave', () => {
              instance.exportInstantJSON().then(instantJson => {
                // Remove pdfId so it can load from localStorage without conflict
                delete instantJson.pdfId
                setDocumentAnnotations({ annotations: (instantJson as DocumentAnnotation), fileName: props.fileName })
              })
            })
          })
        }
      }
    }

    watch(() => props.pdfFile, () => {
      loadPSPDFKit()
    })

    watch(toolbarItems, () => {
      loadPSPDFKit()
    })

    onMounted(() => {
      loadPSPDFKit()
    })

    onBeforeUnmount(() => {
      clearDocumentAnnotations()
      if (pdfContainer.value) {
        PSPDFKit.unload(pdfContainer.value)
      }
    })

    return { pdfContainer }
  }
})
