import { DocumentAnnotation, DocumentAnnotationFileName } from '@/domain/documentAnnotation'
import localforage from 'localforage'
import RepositoryError from '@/domain/repositoryError'

class DocumentAnnotationRepository {
  async load (fileName: string) {
    try {
      const annotations = await localforage.getItem(`annotations[${fileName}]`)
      return <DocumentAnnotation> annotations
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  set ({ annotations, fileName }: DocumentAnnotationFileName) {
    try {
      localforage.setItem(`annotations[${fileName}]`, annotations)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async clearOld (agendaIds?: string[]) {
    try {
      const localForageKeys = await localforage.keys()

      if (agendaIds && agendaIds.length) {
        const localForageAgendaIds = localForageKeys.map(x => {
          return {
            id: x.substring(12, x.indexOf('_')),
            key: x
          }
        })

        localForageAgendaIds.forEach((x) => {
          // If id is not included in the agendaIds
          if (!agendaIds.includes(x.id)) {
            // remove from localforage
            localforage.removeItem(x.key)
          }
        })
      }

      return true
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async clearAll () {
    try {
      return await localforage.clear()
    } catch (err) {
      throw new RepositoryError(err)
    }
  }
}

export default new DocumentAnnotationRepository()
