




import { defineComponent, InjectionKey, onBeforeUnmount, onMounted, provide, Ref } from '@vue/composition-api'
import { useMachine } from 'xstate-vue2'
import { useSystem } from '@/composables/useSystem'
import { authMachine, initializeAuthMachine, MachineEvent } from '@/machines/authMachine'
import { electronAuthMachine } from '@/machines/electronAuthMachine'
import emitter from '@/utils/emitter'
import { EventData, State } from 'xstate'
import { Event } from 'xstate/lib/types'

export const authMachineStateKey: InjectionKey<Ref<State<any, any>>> = Symbol('authMachineState')
export const authMachineSendKey: InjectionKey<(event: Event<MachineEvent>, payload?: EventData) => State<any>> = Symbol('authMachineSend')

export default defineComponent({
  setup () {
    const { isElectron, checkElectron } = useSystem()
    checkElectron()

    // Use different auth machine if electron is detected
    let state, send
    if (isElectron.value) {
      ({ state, send } = useMachine(electronAuthMachine))
      const ipcRenderer = window.require('electron').ipcRenderer
      ipcRenderer.on('auth-response', (event, response) => {
        send({ type: 'SSO_LOGIN_REDIRECT', data: response })
      })
    } else {
      ({ state, send } = useMachine(authMachine))
      initializeAuthMachine()
    }

    // provide variables
    provide(authMachineStateKey, state)
    provide(authMachineSendKey, send)
    // listen for auth events
    emitter.on('API_401', () => send({ type: 'API_401' }))
    emitter.on('API_403', () => send({ type: 'API_403' }))
    emitter.on('ONLINE', () => send('ONLINE'))
    emitter.on('OFFLINE', () => send('OFFLINE'))
    emitter.on('SSO_LOGIN_REDIRECT', (response: any) => send({ type: 'SSO_LOGIN_REDIRECT', data: response }))
    emitter.on('SSO_LOGIN_CACHED', (response: any) => send({
      type: 'SSO_LOGIN_CACHED',
      data: {
        account: {
          username: response
        }
      }
    }))

    // listen to window changes
    onMounted(() => {
      window.addEventListener('online', () => emitter.emit('ONLINE'))
      window.addEventListener('offline', () => emitter.emit('OFFLINE'))
    })
    // unlisten to window changes
    onBeforeUnmount(() => {
      window.removeEventListener('online', () => emitter.emit('ONLINE'))
      window.removeEventListener('offline', () => emitter.emit('OFFLINE'))
    })
    return { state }
  }
})
