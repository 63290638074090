import Vue from 'vue'
import Vuex from 'vuex'
import agendas from '@/store/modules/agendas'
import users from '@/store/modules/users'
import system from '@/store/modules/system'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    agendas,
    users,
    system
  }
})

export default store
