import Vue from 'vue'
import './plugins/composition'
import store from './store'
import router from './router'
import './filters'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import http from './utils/axios'
import AppOutlet from './router/views/AppOutlet.vue'

import '@zucommunications/gsk-docshare-web-design-system/src/scss/fonts.scss'

// https://vuejs.org/v2/cookbook/adding-instance-properties.html#Real-World-Example-Replacing-Vue-Resource-with-Axios
Vue.prototype.$http = http
Vue.config.productionTip = false

let instance

const init = () => {
  return new Vue({
    store,
    vuetify,
    router,
    i18n,
    render: h => h(AppOutlet)
  }).$mount('#app')
}

function loadScript (url) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.onload = resolve
    script.onerror = reject
    script.src = url
    document.getElementsByTagName('head')[0].appendChild(script)
  })
}

if (window.location !== window.parent.location) {
  loadScript('https://appsforoffice.microsoft.com/lib/1/hosted/office.js').then(() => {
    window.Office.onReady().then(() => {
      instance = init()
    })
  })
} else {
  instance = init()
}

export default instance
