import { computed } from '@vue/composition-api'
import store from '@/store'

export function useSystem () {
  const checkElectron = () => {
    const userAgent = navigator.userAgent.toLowerCase()
    const isElectron = userAgent.indexOf(' electron/') > -1

    store.commit('system/setIsElectron', isElectron)
  }

  // @ts-ignore: Unreachable code error
  const isElectron = computed(() => store.state.system.isElectron)

  return {
    checkElectron,
    isElectron
  }
}
