import { GroupMembershipMapper } from '@/domain/groupMembershipMapper'
import { User, UserId } from '@/domain/user'

export const UserMapper = ({
  deserialize: (json): User => {
    const userId = +json['@id'].replace('/api/users/', '')
    const hasRoleAdmin = Boolean(json.roles?.includes('ROLE_ADMIN'))
    const hasRoleAgenda = Boolean(json.roles?.includes('ROLE_AGENDA'))
    const hasRoleGroupAdmin = Boolean(json.UserUserGroup ? json.UserUserGroup.some(group => group.admin) : false)
    const canCreateAgenda = hasRoleAdmin || hasRoleAgenda
    const canManageAgendas = hasRoleAdmin || hasRoleAgenda
    const canManageTrackingLists = hasRoleAdmin
    const canManage = canManageAgendas || canManageTrackingLists
    return <User>{
      id: userId,
      email: json.email,
      firstName: json.givenName,
      lastName: json.surName,
      roles: json.roles,
      rolesEditable: json.roles?.filter(x => ['ROLE_ADMIN', 'ROLE_AGENDA', 'ROLE_JAG'].includes(x)) ?? [],
      groupMembership: json.UserUserGroup ? GroupMembershipMapper.deserialize(json, <UserId>{
        id: userId
      }) : [],
      isAdmin: hasRoleAdmin,
      isGroupAdmin: hasRoleGroupAdmin,
      isActive: json.active,
      usersForImpersonation: json.peopleToDelegate ? Object.keys(json.peopleToDelegate).map(key => {
        const email = json.peopleToDelegate[key]
        const nameParts = key.split(' ')
        const firstName = nameParts.shift()
        const lastName = nameParts.join(' ')
        return <User>{
          firstName,
          email,
          lastName
        }
      }) : [],
      canCreateAgenda,
      canManageAgendas,
      canManageTrackingLists,
      canManage
    }
  },
  serializeIri: (user: User): string => {
    return `/api/users/${user.id}`
  },
  serialize: (user: User): Object => {
    return {
      email: user.email,
      surName: user.lastName,
      givenName: user.firstName,
      roles: user.roles
    }
  }
})
