import { AgendaApi } from '@zucommunications/gsk-docshare-web-api'
import axios from '@/utils/axios'
import { IFindData } from '@/domain/interfaces'
import { AgendaManifest } from '@/domain/agendaManifest'
import RepositoryError from '@/domain/repositoryError'

const BASE_PATH = window.Cypress ? '' : process.env.VUE_APP_SYMFONY_API_URL
const api = new AgendaApi(undefined, BASE_PATH, axios)

class AgendaManifestRepository implements IFindData<AgendaManifest> {
  async findById (id) {
    try {
      const response = await api.getAgendaManifestAgendaItem(id)
      return <AgendaManifest> (response.data as unknown)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }
}

export default new AgendaManifestRepository()
