























import { defineComponent, inject } from '@vue/composition-api'
import CabreaderLogoWhite from '@/assets/cabreaderWhite.svg'
import { BaseButton, BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { useAgendas } from '@/composables/useAgendas'
import { Interpreter } from 'xstate'
import { useService } from 'xstate-vue2'

export default defineComponent({
  name: 'app-bar',
  components: {
    CabreaderLogoWhite,
    BaseButton,
    BaseIcon
  },
  setup () {
    const service = inject('sidebarMachineService') as Interpreter<any, any, any, any>
    const { send } = useService(service)

    const { refresh } = useAgendas()

    return { send, refresh }
  }
})
