




































import { defineComponent, computed, onBeforeUnmount } from '@vue/composition-api'
import PSPDFKitContainer from '@/components/PSPDFKitContainer.vue'
import AppBar from '@/components/AppBar.vue'
import { BaseButton, BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'

export default defineComponent({
  components: {
    PSPDFKitContainer,
    AppBar,
    BaseButton,
    BaseIcon
  },
  setup (_, { root }) {
    // TODO: migrate to `const store = useStore()`
    const store = root.$store
    const urlCreator = window.URL || window.webkitURL

    const mediaBlob = computed(() => root.$store.state.agendas.mediaBlob)
    const mediaUrl = computed(() => mediaBlob.value ? urlCreator.createObjectURL(mediaBlob.value.blob) : undefined)
    const fileName = computed(() => mediaBlob.value ? `${mediaBlob.value.agendaId}_${mediaBlob.value.file}` : undefined)

    onBeforeUnmount(() => {
      if (mediaUrl.value) {
        urlCreator.revokeObjectURL(mediaUrl.value)
        store.commit('agendas/removeMediaBlobFromCache')
      }
    })

    return {
      mediaBlob,
      mediaUrl,
      fileName
    }
  }
})
