import { UserApi } from '@zucommunications/gsk-docshare-web-api'
import axios from '@/utils/axios'
import { IFindData, ISearchData } from '@/domain/interfaces'
import { User } from '@/domain/user'
import { UserMapper } from '@/domain/userMapper'
import RepositoryError from '@/domain/repositoryError'

const BASE_PATH = window.Cypress ? '' : process.env.VUE_APP_SYMFONY_API_URL
const api = new UserApi(undefined, BASE_PATH, axios)

class UserRepository implements IFindData<User>, ISearchData<User> {
  async findByCriteria (params) {
    try {
      const response = await this.search(params)
      return response.results
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async findById (id: string | number) {
    try {
      const response = await api.getUserItem(String(id))
      return UserMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async invite ({ email }) {
    try {
      const response = await api.postInviteUserUserCollection({ email })
      return { id: String(response.data) }
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async search ({
    page = 1,
    itemsPerPage = 5,
    pagination = true,
    orderGivenName,
    orderSurName,
    orderEmail
  } : {
    page?: number;
    itemsPerPage?: number;
    pagination?: boolean;
    orderGivenName?: 'asc' | 'desc';
    orderSurName?: 'asc' | 'desc';
    orderEmail?: 'asc' | 'desc';
  }) {
    try {
      const response = await api.getUserCollection(
        page,
        itemsPerPage,
        pagination,
        undefined,
        undefined,
        undefined,
        undefined,
        orderGivenName,
        orderSurName,
        orderEmail,
        undefined
      )
      return <SearchResult<User>> {
        pageCount: Math.ceil(+response.data['hydra:totalItems'] / itemsPerPage) || 1,
        resultsTotal: +response.data['hydra:totalItems'] || 0,
        results: response.data['hydra:member'].map(x => UserMapper.deserialize(x))
      }
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async update (obj: User) {
    try {
      const response = await api.patchUserItem(String(obj.id), UserMapper.serialize(obj))
      return UserMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async activate (id: string | number) {
    try {
      return await api.postActivateUserUserItem(String(id), {})
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async deactivate (id: string | number) {
    try {
      return await api.postDeactivateUserUserItem(String(id), {})
    } catch (err) {
      throw new RepositoryError(err)
    }
  }
}

export default new UserRepository()
