



































































































































































import { defineComponent, computed, ref } from '@vue/composition-api'
import ButtonOpen from '@/components/ButtonOpen.vue'
import Settings from '@/components/Settings.vue'
import SidebarDrawer from '@/components/SidebarDrawer.vue'
import SidebarAgendaManifest from '@/components/SidebarAgendaManifest.vue'
import SidebarButton from '@/components/SidebarButton.vue'
import { BaseButton, BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { useSystem } from '@/composables/useSystem'
import { useAgendas } from '@/composables/useAgendas'

export default defineComponent({
  name: 'sidebar-base',
  props: {
    state: {
      type: Object,
      required: true
    }
  },
  components: {
    ButtonOpen,
    Settings,
    SidebarDrawer,
    SidebarAgendaManifest,
    SidebarButton,
    BaseButton,
    BaseIcon
  },
  setup (props, { root, emit }) {
    // TODO: migrate to `const store = useStore()`
    const store = root.$store
    const { isElectron } = useSystem()
    const { savedAgendaIds, downloadAgendaZip, clearAgendaZip } = useAgendas()
    const loading = ref(null)
    const downloadAgenda = async (id) => {
      loading.value = id
      await downloadAgendaZip(id)
      loading.value = null
    }

    const helpItem = {
      href: !isElectron.value ? `${process.env.VUE_APP_SHAREPOINT_URL}/sites/docshare-trnassetsSP` : undefined,
      target: !isElectron.value ? '_blank' : undefined,
      click: isElectron.value ? () => window.require('electron').shell.openExternal(`${process.env.VUE_APP_SHAREPOINT_URL}/sites/docshare-trnassetsSP`) : () => {}
    }

    // Get agenda categories from store to update with changes made in sidebar
    const agendaCategories = computed(() => store.getters['agendas/getAgendaCategories'])
    const agendaCategory = computed(() => props.state.context.agendaCategory)
    // Get category agendas from store to update with changes made in sidebar
    const selectedCategoryAgendas = computed(() => store.getters['agendas/getSelectedCategoryAgendas'](agendaCategory.value))

    const checkAgendaDownloadStatus = (agendaId) => {
      if (!isElectron.value) {
        return false
      }

      if (savedAgendaIds.value && savedAgendaIds.value.length) {
        return !savedAgendaIds.value.includes(`${agendaId}`)
      }
      return false
    }

    return {
      clearAgendaZip,
      downloadAgenda,
      loading,
      helpItem,
      agendaCategories,
      agendaCategory,
      selectedCategoryAgendas,
      checkAgendaDownloadStatus
    }
  }
})
