import { Machine, assign } from 'xstate'
import { TokenApi } from '@zucommunications/gsk-docshare-web-api/index'
import axios from '@/utils/axios'
import { useUser } from '@/composables/useUser'

let ipcRenderer
if (process.env.IS_ELECTRON) {
  ipcRenderer = window.require('electron').ipcRenderer
}

export type MachineEvent = {
  type: string;
  data?: any;
}

interface MachineContext {
  ssoTokenLoginHint: string | undefined;
  ssoToken: string | undefined;
  ssoTokenExpiry: Date | undefined;
  ssoError: string | undefined;
  ssoUserImpersonation: string | undefined;
  apiToken: string | undefined;
  apiError: string | undefined;
  authAttemptCount: number;
  inactiveUser: boolean;
}

export const electronAuthMachine = Machine<MachineContext, any, MachineEvent>({
  id: 'auth',
  initial: 'online',
  on: {
    SSO_LOGOUT_APP: {
      target: '#authenticated.confirming_logout',
      actions: 'log'
    },
    SSO_LOGIN_REDIRECT: {
      target: '#authenticated.exchanging',
      actions: ['log', 'setSsoInformation']
    },
    SSO_IMPERSONATION_ENABLE: {
      actions: ['setUserImpersonation', 'reload']
    },
    SSO_IMPERSONATION_DISABLE: {
      actions: ['clearUserImpersonation', 'reload']
    },
    API_403: {
      target: '#anonymous.userRestricted',
      actions: 'clearStorage'
    }
  },
  context: {
    ssoError: undefined,
    apiError: undefined,
    authAttemptCount: 0,
    inactiveUser: false,
    ssoToken: window.localStorage.getItem('ssoToken') || undefined,
    ssoTokenExpiry: window.localStorage.getItem('ssoTokenExpiry') ? new Date(parseInt(String(window.localStorage.getItem('ssoTokenExpiry')))) : undefined,
    ssoTokenLoginHint: window.localStorage.getItem('ssoTokenLoginHint') || undefined,
    ssoUserImpersonation: window.localStorage.getItem('ssoUserImpersonation') || undefined,
    apiToken: window.localStorage.getItem('apiToken') || undefined
  },
  states: {
    offline: {
      on: {
        ONLINE: 'online'
      }
    },
    online: {
      initial: 'anonymous',
      on: {
        OFFLINE: 'offline'
      },
      states: {
        anonymous: {
          initial: 'init',
          id: 'anonymous',
          states: {
            init: {
              always: [{
                target: '#authenticated.refreshing',
                cond: 'isLoggedIn'
              }, {
                target: 'ssoLogin',
                cond: 'isNotAuthRedirect'
              }, {
                target: 'purgatory'
              }]
            },
            ssoLogin: {
              invoke: {
                src: 'ssoLogin',
                onDone: [{
                  target: '#authenticated.connected',
                  actions: 'log'
                }],
                onError: [{
                  target: 'userRestricted',
                  cond: 'isInactiveUser'
                }, {
                  target: 'backendUnavailable',
                  cond: 'maxAuthAttemptCount'
                }, {
                  target: '#anonymous',
                  actions: 'incrementAuthAttemptCount'
                }]
              }
            },
            purgatory: {},
            userRestricted: {},
            backendUnavailable: {}
          }
        },
        authenticated: {
          id: 'authenticated',
          states: {
            refreshing: {
              entry: 'log',
              invoke: {
                src: 'ssoSilent',
                onDone: {
                  target: 'connected',
                  actions: 'resetAuthAttemptCount'
                },
                onError: [{
                  target: '#anonymous.ssoLogin',
                  cond: 'isLoginRequired'
                }, {
                  target: '#anonymous.backendUnavailable',
                  cond: 'maxAuthAttemptCount'
                }, {
                  target: 'refreshingFailure',
                  actions: 'incrementAuthAttemptCount'
                }]
              }
            },
            refreshingFailure: {
              entry: 'log',
              after: {
                RETRY_DELAY: 'refreshing'
              }
            },
            exchanging: {
              entry: 'log',
              invoke: {
                // Exchange idToken for JWT (on behalf of flow)
                src: 'jwtTokenExchange',
                onDone: {
                  target: 'connected',
                  actions: ['setApiInformation', 'resetAuthAttemptCount']
                },
                onError: [{
                  target: '#anonymous.userRestricted',
                  cond: 'isInactiveUser'
                }, {
                  target: '#anonymous.backendUnavailable',
                  cond: 'maxAuthAttemptCount'
                }, {
                  target: 'exchangingFailure',
                  actions: 'incrementAuthAttemptCount'
                }]
              }
            },
            exchangingFailure: {
              entry: 'log',
              after: {
                RETRY_DELAY: 'exchanging'
              }
            },
            connected: {
              entry: 'log',
              after: {
                REFRESH_DELAY: 'refreshing'
              },
              on: {
                API_401: {
                  target: 'refreshing'
                }
              }
            },
            confirming_logout: {
              on: {
                YES: {
                  target: '#anonymous',
                  actions: ['clearStorage', 'clearUserData', 'ssoLogout']
                },
                NO: 'connected'
              }
            }
          }
        }
      }
    }
  }
}, {
  delays: {
    REFRESH_DELAY: 10 * 60 * 1000,
    RETRY_DELAY: 2 * 1000
  },
  actions: {
    log: (context, event) => {
      console.log('electronAuthMachineEvent', event.type)
    },
    reload: () => {
      window.location.replace('/')
    },
    setSsoInformation: assign((context, event) => {
      const ssoToken = event.data.idToken
      const ssoTokenExpiry = event.data.expiresOn
      const ssoTokenLoginHint = event.data.account ? event.data.account.username : undefined
      window.localStorage.setItem('ssoToken', ssoToken)
      window.localStorage.setItem('ssoTokenExpiry', ssoTokenExpiry.getTime().toString())
      window.localStorage.setItem('ssoTokenLoginHint', ssoTokenLoginHint)
      axios.defaults.headers.post['MS-Authorization'] = ssoToken
      return {
        ssoToken,
        ssoTokenExpiry,
        ssoTokenLoginHint
      }
    }),
    setUserImpersonation: assign((context, event) => {
      const ssoUserImpersonation = event.data.user
      window.localStorage.setItem('ssoUserImpersonation', ssoUserImpersonation)
      axios.defaults.headers.common['HTTP-X-SWITCH-USER'] = ssoUserImpersonation
      return {
        ssoUserImpersonation
      }
    }),
    clearUserImpersonation: assign((context, event) => {
      window.localStorage.removeItem('ssoUserImpersonation')
      delete axios.defaults.headers.common['HTTP-X-SWITCH-USER']
      return {
        ssoUserImpersonation: undefined
      }
    }),
    setApiInformation: assign((context, event: any) => {
      const apiToken = event.data.token
      window.localStorage.setItem('apiToken', apiToken)
      axios.defaults.headers.common.Authorization = `bearer ${apiToken}`
      return {
        apiToken
      }
    }),
    clearStorage: () => {
      window.localStorage.removeItem('ssoToken')
      window.localStorage.removeItem('ssoTokenExpiry')
      window.localStorage.removeItem('ssoTokenLoginHint')
      window.localStorage.removeItem('ssoUserImpersonation')
      window.localStorage.removeItem('apiToken')
      delete axios.defaults.headers.common.Authorization
      delete axios.defaults.headers.common['HTTP-X-SWITCH-USER']
      delete axios.defaults.headers.post['MS-Authorization']
    },
    clearUserData: async () => {
      const { clearUserData } = useUser()
      await clearUserData()
    },
    ssoLogout: async (context, event) => {
      await ipcRenderer.invoke('logout')
    },
    incrementAuthAttemptCount: (context, event) => {
      context.authAttemptCount += 1
    },
    resetAuthAttemptCount: (context, event) => {
      context.authAttemptCount = 0
    }
  },
  services: {
    ssoLogin: async (context, event) => {
      await ipcRenderer.invoke('login')
    },
    ssoSilent: async (context, event) => {
      await ipcRenderer.invoke('get-token')
    },
    jwtTokenExchange: async (context, event) => {
      const api = new TokenApi(undefined, process.env.VUE_APP_SYMFONY_API_URL, axios)
      try {
        const response = await api.postMSAuthentication()
        return Promise.resolve(response.data)
      } catch (err: any) {
        if (err.response && err.response.status === 403) {
          context.inactiveUser = true
        }
        return Promise.reject(err)
      }
    }
  },
  guards: {
    isNotAuthRedirect: (context, event) => {
      return !window.location.href.includes('redirect')
    },
    isLoggedIn: (context, event) => {
      return window.localStorage.getItem('ssoToken') !== null
    },
    isLoginRequired: (context, event) => {
      return event.data.errorMessage.includes('AADSTS50058')
    },
    isInactiveUser: (context, event) => {
      return context.inactiveUser
    },
    maxAuthAttemptCount: (context, event) => {
      return context.authAttemptCount >= 7 // will trigger on 8th attempt
    }
  }
})
