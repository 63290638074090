import { Machine, assign } from 'xstate'
import repository from '@/domain/documentAnnotationRepository'
import { useAgendas } from '@/composables/useAgendas'
import { useUser } from '@/composables/useUser'
import { useSystem } from '@/composables/useSystem'

export const electronSidebarMachine = Machine<any>({
  id: 'sidebar',
  initial: 'loading',
  context: {
    showSidebar: false,
    showSettings: false,
    agendaCategory: undefined,
    agenda: undefined,
    error: undefined
  },
  states: {
    loading: {
      invoke: {
        src: 'load',
        onDone: {
          target: 'idle',
          actions: ['log', 'clearPercentage']
        },
        onError: {
          target: '#error',
          actions: ['setError', 'clearPercentage']
        }
      }
    },
    idle: {
      id: 'idle'
    },
    sidebar: {
      initial: 'init',
      states: {
        init: {
          initial: 'init_init',
          states: {
            init_init: {
              on: {
                SELECT_AGENDA_CATEGORY: {
                  target: '#selecting_agenda',
                  actions: ['log', 'setAgendaCategory']
                },
                TOGGLE_SETTINGS: {
                  actions: ['log', 'toggleSettings']
                },
                CLEAR_AGENDAS: {
                  target: '#confirming_clear_agendas',
                  actions: 'log'
                },
                CLEAR_ANNOTATIONS: {
                  target: '#confirming_clear_annotations',
                  actions: 'log'
                }
              }
            },
            confirming_clear_agendas: {
              id: 'confirming_clear_agendas',
              on: {
                YES: {
                  target: 'init_init',
                  actions: ['log', 'clearAgendas']
                },
                NO: 'init_init'
              }
            },
            confirming_clear_annotations: {
              id: 'confirming_clear_annotations',
              on: {
                YES: {
                  target: 'init_init',
                  actions: ['log', 'clearAnnotations']
                },
                NO: 'init_init'
              }
            }
          }
        },
        selecting_agenda: {
          id: 'selecting_agenda',
          initial: 'agenda_init',
          states: {
            agenda_init: {
              on: {
                LOAD_AGENDA_MANIFEST: {
                  target: 'agenda_loading',
                  actions: ['log', 'setAgenda']
                }
              }
            },
            agenda_loading: {
              invoke: {
                src: 'loadManifest',
                onDone: {
                  target: '#selecting_document',
                  actions: 'log'
                },
                onError: {
                  target: '#error',
                  actions: 'setError'
                }
              }
            }
          },
          on: {
            BACK: {
              target: 'init'
            }
          }
        },
        selecting_document: {
          id: 'selecting_document',
          initial: 'document_init',
          states: {
            document_init: {
              on: {
                BACK: {
                  target: '#selecting_agenda'
                },
                TOGGLE_SIDEBAR: {
                  actions: 'toggleSidebar'
                },
                LOAD_AGENDA_DOCUMENT: {
                  target: 'document_loading',
                  actions: 'log'
                }
              }
            },
            document_loading: {
              invoke: {
                src: 'loadDocument',
                onDone: {
                  target: 'document_init',
                  actions: ['log', 'toggleSidebar']
                },
                onError: {
                  target: '#error',
                  actions: 'setError'
                }
              }
            }
          },
          exit: ['clearAgenda', 'clearAgendaManifest']
        }
      },
      on: {
        TOGGLE_SIDEBAR: {
          target: '#idle',
          actions: 'toggleSidebar'
        }
      },
      exit: 'clearAgendaCategory'
    },
    error: {
      id: 'error',
      entry: 'closeSidebar',
      on: {
        CLEAR_ERROR: {
          target: 'idle',
          actions: ['log', 'clearError']
        },
        TOGGLE_SIDEBAR: {
          target: 'sidebar',
          actions: ['toggleSidebar', 'clearError']
        }
      }
    }
  },
  on: {
    TOGGLE_SIDEBAR: {
      target: 'sidebar',
      actions: 'toggleSidebar'
    },
    REFRESH_AGENDAS: {
      target: 'loading',
      actions: ['log', 'closeSidebar']
    }
  }
}, {
  services: {
    load: async () => {
      // Check if online
      if (navigator.onLine) {
        const { findForSidebar, findSavedAgendaIds, downloadAgendaZips, agendasForSidebar, updateRefresh, clearOldAgendaZips } = useAgendas()
        await findForSidebar()

        await downloadAgendaZips()
        await findSavedAgendaIds()

        // Get/set current user
        const { findMe } = useUser()
        await findMe()

        // Update refresh if agendas array is empty
        if (!agendasForSidebar.value || Object.keys(agendasForSidebar.value).length === 0) {
          updateRefresh(true)
        }

        await clearOldAgendaZips()
      } else {
        const { findForSideBarOffline, findSavedAgendaIds, updateRefresh } = useAgendas()
        const { findMeOffline } = useUser()

        await findForSideBarOffline()
        await findSavedAgendaIds()
        await findMeOffline()
      }
    },
    loadManifest: async (_, event) => {
      const { findManifest } = useAgendas()
      await findManifest(event.value.id)
    },
    loadDocument: async (_, event) => {
      const { findMediaBlob } = useAgendas()
      await findMediaBlob(event.value)
    }
  },
  actions: {
    log: (_, event) => {
      console.log('electronSidebarMachineEvent', event.type, event.payload)
    },
    closeSidebar: assign({
      showSidebar: false
    }),
    toggleSidebar: assign({
      showSidebar: (context) => !context.showSidebar
    }),
    toggleSettings: assign({
      showSettings: (context) => !context.showSettings
    }),
    setAgendaCategory: assign({
      agendaCategory: (_, event: any) => event.value
    }),
    clearAgendaCategory: assign({
      agendaCategory: undefined
    }),
    setAgenda: assign({
      agenda: (_, event: any) => event.value
    }),
    clearAgenda: assign({
      agenda: undefined
    }),
    clearPercentage: () => {
      const { updatePercentage } = useAgendas()
      updatePercentage(0)
    },
    clearAgendaManifest: () => {
      const { clearAgendaManifest } = useAgendas()
      clearAgendaManifest()
    },
    clearAgendas: () => {
      const { clearAgendas } = useAgendas()
      clearAgendas()
    },
    clearAnnotations: () => {
      repository.clearAll()
    },
    setError: assign({
      error: (_, event: any) => event.data
    }),
    clearError: assign({
      error: undefined
    })
  }
})
