




































































import { defineComponent } from '@vue/composition-api'
import CabreaderIcon from '@/assets/cabreaderIcon.svg'
import Auth from '@/components/Auth.vue'

export default defineComponent({
  name: 'AppOutlet',
  components: {
    CabreaderIcon,
    Auth
  },
  setup (_, { root }) {
    const router = root.$router
    const reloadPage = () => {
      router.go(0)
    }

    return {
      reloadPage
    }
  }
})
