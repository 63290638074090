import { AgendaAgendaPatch, AgendaJsonld, AgendaJsonldAgendaWritePreview, AgendaJsonldAgendaRead, AgendaItemAgendaPatch, AgendaJsonldAgendaPost, AgendaSectionAgendaPatch } from '@zucommunications/gsk-docshare-web-api'
import { AgendaTemplateMapper } from '@/domain/agendaTemplateMapper'
import { DocumentMapper } from '@/domain/documentMapper'
import { MediaMapper } from '@/domain/mediaMapper'
import { Agenda, AgendaItem, AgendaMilestone, AgendaSection } from '@/domain/agenda'
import { UserMapper } from '@/domain/userMapper'

export const AgendaMapper = ({
  deserialize: (json): Agenda => { // (json: AgendaJsonldAgendaRead): Agenda => {
    return <Agenda>{
      id: Number(json['@id'].replace('/api/agendas/', '')),
      status: json.status,
      title: json.title,
      isActive: json.isActive,
      isPreview: json.isPreview ? json.isPreview : false,
      meetingDate: json.meetingDate ? new Date(json.meetingDate) : undefined,
      dateCreated: json.dateCreated ? new Date(json.dateCreated) : undefined,
      template: json.agendaGroup ? AgendaTemplateMapper.deserialize(json.agendaGroup) : undefined,
      sections: json.sections?.length ? json.sections.map(AgendaSectionMapper.deserialize) : [],
      generationDate: json.generationDate ? new Date(json.generationDate) : undefined,
      usersForPreview: json.agendaGroup?.secretary?.length ? json.agendaGroup.secretary.map(UserMapper.deserialize) : [],
      milestones: json.agendaReleases?.length ? json.agendaReleases?.map(AgendaMilestoneMapper.deserialize) : []
    }
  },
  serializeIri: (obj: Agenda): string => {
    return `/api/agendas/${obj.id}`
  },
  serializePost: (obj: Agenda): AgendaJsonldAgendaPost => {
    return {
      title: obj.title || '',
      meetingDate: obj.meetingDate ? (new Date(obj.meetingDate)).toISOString() : '',
      agendaGroup: obj.template ? AgendaTemplateMapper.serializeIri(obj.template) : undefined
    }
  },
  serializePatch: (obj: Agenda): AgendaAgendaPatch => {
    return {
      title: obj.title || '',
      meetingDate: obj.meetingDate ? (new Date(obj.meetingDate)).toISOString() : '',
      agendaGroup: obj.template ? AgendaTemplateMapper.serializeIri(obj.template) : undefined,
      sections: obj.sections?.length ? obj.sections.map(AgendaSectionMapper.serializePatch) : []
    }
  },
  serializeRelease: (obj: Agenda): AgendaJsonld => {
    return {
      id: AgendaMapper.serializeIri(obj)
    }
  },
  serializePreview: (obj: Agenda): AgendaJsonldAgendaWritePreview => {
    return {
      id: AgendaMapper.serializeIri(obj),
      previewToUsers: obj.usersForPreview?.map(UserMapper.serializeIri) || undefined
    }
  }
})

export const AgendaSectionMapper = ({
  deserialize: (json): AgendaSection => {
    return <AgendaSection>{
      id: Number(json['@id'].replace('/api/agenda_sections/', '')),
      type: json.type,
      title: json.title,
      position: json.position,
      children: json.agendaItems?.length ? json.agendaItems.map(AgendaItemMapper.deserialize) : []
    }
  },
  serializeIri: (obj: AgendaSection): string => {
    return `/api/agenda_sections/${obj.id}`
  },
  serializePatch: ({ title = '', children = [] }: AgendaSection, position: number): AgendaSectionAgendaPatch => {
    return {
      title,
      position,
      agendaItems: children?.length ? children.reduce((acc, val) => {
        if (!val.existingAttachment) {
          acc.push(AgendaItemMapper.serializePatch(val, position))
        }
        return acc
      }, [] as AgendaItemAgendaPatch[]) : []
    }
  }
})

export const AgendaItemMapper = ({
  deserialize: (json): AgendaItem => {
    return <AgendaItem>{
      id: Number(json['@id'].replace('/api/agenda_items/', '')),
      type: json.type,
      title: json.title,
      position: json.position,
      held: json.held,
      document: json.document ? DocumentMapper.deserialize(json.document) : undefined,
      attachment: json.attachment ? MediaMapper.deserialize(json.attachment) : undefined,
      children: json.agendaItems?.length ? json.agendaItems.map(AgendaItemMapper.deserialize) : []
    }
  },
  serializeIri: (obj: AgendaItem): string => {
    return `/api/agenda_items/${obj.id}`
  },
  serializePatch: ({ title = '', children = [], document, attachment, held }: AgendaItem, position: number): AgendaItemAgendaPatch => {
    return {
      title,
      position,
      document: document ? DocumentMapper.serializeIri(document) : undefined,
      attachment: attachment ? MediaMapper.serializeIri(attachment) : undefined,
      agendaItems: children?.length ? children.reduce((acc, val) => {
        if (!val.existingAttachment) {
          acc.push(AgendaItemMapper.serializePatch(val, position))
        }
        return acc
      }, [] as AgendaItemAgendaPatch[]) : [],
      held
    }
  }
})

export const AgendaMilestoneMapper = ({
  deserialize: (json): AgendaMilestone => {
    return <AgendaMilestone>{
      title: json.subject.replace(/\w/, firstLetter => firstLetter.toUpperCase()),
      date: json.date ? new Date(json.date) : undefined
    }
  }
})
