<template>
  <div class="ui-home" :style="{ backgroundImage: `url(${SaskCoatOfArmsWatermark})` }">
    <AppBar />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import AppBar from '@/components/AppBar.vue'
import SaskCoatOfArmsWatermark from '@/assets/SaskCoatOfArmsWatermark.png'

export default defineComponent({
  name: 'Home',
  components: {
    AppBar
  },
  setup () {
    return { SaskCoatOfArmsWatermark }
  }
})
</script>

<style lang="scss" scoped>
.ui-home {
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
@media only screen and (min-width: 960px) {
  .ui-home {
    background-size: 50%;
  }
}
@media only screen and (min-width: 1280px) {
  .ui-home {
    background-size: 30%;
  }
}
</style>
