import { WorkflowStepMapper } from '@/domain/workflowGroupMapper'
import { WorkflowDocumentPatch } from '@zucommunications/gsk-docshare-web-api'
import { Workflow } from '@/domain/workflow'

export const WorkflowMapper = ({
  deserialize: (json): Workflow => {
    return <Workflow>{
      dueDate: json.dueDate ? new Date(json.dueDate) : undefined,
      dateCompleted: json.dateCompleted ? new Date(json.dateCompleted) : undefined,
      dateStarted: json.dateStarted ? new Date(json.dateStarted) : undefined,
      workflowStepsRequired: json.workflowStepsRequired?.length ? json.workflowStepsRequired.map(WorkflowStepMapper.deserialize) : [],
      workflowStepsAdditional: json.workflowStepsAdditional?.length ? json.workflowStepsAdditional.map(WorkflowStepMapper.deserialize) : []
    }
  },
  serialize: (obj: Workflow): WorkflowDocumentPatch => {
    return {
      dueDate: obj.dueDate?.toISOString(),
      workflowStepsRequired: obj.workflowStepsRequired?.length ? obj.workflowStepsRequired.map(WorkflowStepMapper.serialize) : [],
      workflowStepsAdditional: obj.workflowStepsAdditional?.length ? obj.workflowStepsAdditional.map(WorkflowStepMapper.serialize) : []
    }
  }
})
