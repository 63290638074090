

































import { defineComponent, computed } from '@vue/composition-api'
import { BaseButton } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { useAgendas } from '@/composables/useAgendas'

export default defineComponent({
  name: 'sidebar-agenda-manifest',
  components: {
    BaseButton
  },
  props: {
    state: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const { agendaManifest } = useAgendas()
    const agenda = computed(() => props.state.context.agenda)

    const status = computed(() => {
      if (agenda.value) {
        const present = new Date()
        if (agenda.value.meetingDate >= present) {
          return 'Upcoming'
        } else {
          return 'Past Meeting'
        }
      }
      return null
    })

    return {
      agenda,
      agendaManifest,
      status
    }
  }
})
